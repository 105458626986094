/* eslint-disable max-len */
// routes.js
const nextRoutes = require('next-routes');

const nRoutes = nextRoutes();
const routes = nRoutes;

const defaultRegexp =
  '/:lang(ro|ru|en)';

// routes.add('search', `${defaultRegexp}/:pageName(search)`, 'search/index');

routes.add('blog', `${defaultRegexp}/:pageName(blog)`, 'blog/index');

routes.add('blogPost', `${defaultRegexp}/:pageName(blog)/:url`, 'blog/post');

routes.add('api', `${defaultRegexp}/:pageName(api)`, 'mapapi');

routes.add('info', `${defaultRegexp}/:pageName(info)/:path`, 'info');

routes.add('city/cityId', `${defaultRegexp}/:pageName(city)/:cityId`, 'city/index');

routes.add('street/streetId', `${defaultRegexp}/:pageName(street)/:streetId`, 'street/index');

routes.add('markers/markersId', `${defaultRegexp}/:pageName(markers)/:markersId?`, 'index');

routes.add('routes/cityId/type/number', `${defaultRegexp}/:pageName(routes)/:cityId/:routeType/:routeNumber`, 'routes/index');

routes.add('routes/cityId', `${defaultRegexp}/:pageName(routes)/:cityId(\\d+)`, 'routes/index');

routes.add('routes', `${defaultRegexp}/:pageName(routes)`, 'routes/index');

routes.add('places/neampornit', `${defaultRegexp}/:pageName(places)/:subpage(neampornit)`, 'neampornit/index');

routes.add('places/neampornit/add', `${defaultRegexp}/:pageName(places)/:subpage(neampornit)/add`, 'neampornit/add');

routes.add('places/neampornit/subcategory', `${defaultRegexp}/:pageName(places)/:subpage(neampornit)/:subcategoryId(\\d+)`, 'neampornit/branches');

routes.add('places/catgeoryUrl/companyId/branchId', `${defaultRegexp}/:pageName(places)/:categoryUrl([A-Za-z_-]+)/:companyId(\\d+)/:branchId(\\d+)`, 'places/company');

routes.add('places/catgeoryUrl/companyId', `${defaultRegexp}/:pageName(places)/:categoryUrl([A-Za-z_-]+)/:companyId(\\d+)`, 'places/company-branches');

routes.add('places/companyId/branchId', `${defaultRegexp}/:pageName(places)/:companyId(\\d+)/:branchId(\\d+)`, 'places/company');

routes.add('places/company-branches', `${defaultRegexp}/:pageName(places)/:companyId(\\d+)`, 'places/company-branches');

routes.add('places/catgeoryUrl', `${defaultRegexp}/:pageName(places)/:categoryUrl([A-Za-z_-]+)`, 'places/category');

routes.add('places/index', `${defaultRegexp}/:pageName(places)`, 'places/index');

routes.add('routing/index', `${defaultRegexp}/:pageName(routing)`, 'routing/index');

// routes.add('d', `${defaultRegexp}/routing`, 'index');
routes.add('point', `${defaultRegexp}/:customPage(point)`, 'index'); // place a marker right to the center of viewport

routes.add('polygon', `${defaultRegexp}/:customPage(polygon)`, 'index'); // place a marker right to the center of viewport

routes.add('b', `${defaultRegexp}/:pageType([a-zA-z]+)`, 'index');

routes.add('c', `${defaultRegexp}`, 'index');

module.exports = nRoutes;

/* eslint-disable max-len */
/* eslint-disable no-empty */
import fetch from 'isomorphic-fetch';
import { Router } from '@/routes';
import {
  IMAGE_UPLOAD_URL,
  IMAGE_UPLOAD_TEMPLATE 
} from '@/constants';

const qs = require('qs');
const Cookies = require('universal-cookie');
const base64 = require('base-64');

const VIEWPORT_ROUTE_PARAMS = 1;
const DEFAULT_LANG = 'ru';

const months = {
  long: [
    { en: 'january', ru: 'января', ro: 'ianuarie' },
    { en: 'february', ru: 'февраля', ro: 'februarie' },
    { en: 'march', ru: 'марта', ro: 'martie' },
    { en: 'april', ru: 'апреля', ro: 'aprilie' },
    { en: 'may', ru: 'мая', ro: 'mai' },
    { en: 'june', ru: 'июня', ro: 'iunie' },
    { en: 'july', ru: 'июля', ro: 'iulie' },
    { en: 'august', ru: 'августа', ro: 'august' },
    { en: 'september', ru: 'сентября', ro: 'septembrie' },
    { en: 'october', ru: 'октября', ro: 'octombrie' },
    { en: 'november', ru: 'ноября', ro: 'noiembrie' },
    { en: 'december', ru: 'декабря', ro: 'decembrie' },
  ],
  short: [
    { en: 'jan', ru: 'янв', ro: 'ian' },
    { en: 'feb', ru: 'фев', ro: 'feb' },
    { en: 'mar', ru: 'мар', ro: 'mar' },
    { en: 'apr', ru: 'апр', ro: 'apr' },
    { en: 'may', ru: 'мая', ro: 'mai' },
    { en: 'jun', ru: 'июн', ro: 'iun' },
    { en: 'jul', ru: 'июл', ro: 'iul' },
    { en: 'aug', ru: 'авг', ro: 'aug' },
    { en: 'sep', ru: 'сен', ro: 'sep' },
    { en: 'oct', ru: 'окт', ro: 'oct' },
    { en: 'nov', ru: 'ноя', ro: 'noi' },
    { en: 'dec', ru: 'дек', ro: 'dec' },
  ],
};

const addLeadingZero = value => (value < 10 ? `0${value}` : `${value}`);

export const humanizeDate = (
  dt,
  lang = 'ru',
  includeYear = true,
  includeTime = true,
  includeSeconds = false
) => {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const a = new Date(dt * 1000);
  const year = a.getFullYear();
  const month = a.getMonth();
  const monthName = months.long[month][lang];
  const date = a.getDate();
  const hour = addLeadingZero(a.getHours());
  const min = addLeadingZero(a.getMinutes());
  const sec = a.getSeconds();
  let inclYear = includeYear;
  if (currentYear > year) {
    inclYear = true;
  }

  return `${date} ${monthName}${inclYear ? ` ${year}` : ''}${
    includeTime ? `, ${hour}:${min}` : `${includeSeconds ? +':' + sec : ''}`
  }`;
};

const isLang = string => string === 'ru' || string === 'ro' || string === 'en';

export const langRedirect = (res, req, cookieLang) => {
  if (req && (req.originalUrl.indexOf('static') > -1 || req.originalUrl.indexOf('api') > -1)) return;
  if (req && req.originalUrl) {
    let urlLang = null;
    if (isLang(req.originalUrl.substring(1, 3))) {
      urlLang = req.originalUrl.substring(1, 3);
    }
    if (urlLang && !cookieLang) {
      return;
    }
    if (urlLang && cookieLang) {
      if (urlLang !== cookieLang) {
        try {
          res.writeHead(302, {
            Location: `${req.originalUrl.replace(urlLang, cookieLang)}`,
          });
          res.end();
        } catch (e) { }
      }
    } else {
      try {
        res.writeHead(302, { Location: `/${cookieLang || DEFAULT_LANG}${req.originalUrl}` });
        res.end();
      } catch (e) { }
    }
    return urlLang;
  }
  return null;
};

export const socialShare = (social) => {
  const href = encodeURIComponent(window.location.href);
  const params = 'toolbar=0,status=0,width=626,height=436';
  const SocialUrls = {
    fb: `https://www.facebook.com/sharer.php?u=${href}`,
    vk: `https://vkontakte.ru/share.php?url=${href}`,
    odn: `https://connect.ok.ru/offer?url=${href}`,
  };
  window.open(SocialUrls[social], 'Share', params);
};

export const getMyUserAccount = async (req, isServer) => {
  // let cookies = null;
  if (req && req.headers && typeof req.headers.cookie !== 'undefined') {
    // cookies = new Cookies(req.headers.cookie);
    // const userAuth = cookies.get('simpalsid.auth') || cookies.get('auth');
    // if (userAuth) {
    //   const resp = await fetch('http://accounts.service.simpals:18333/v1.0/users/me', {
    //     headers: {
    //       Authorization: `Basic ${base64.encode(`${userAuth}:`)}`,
    //     },
    //     mode: 'no-cors',
    //   });
    //   const json = await resp.json();
    //   return { account: json, userAuth };
    // }
  }
  return {};
};

export const checkUserLogged = () => {
  let isLogged = false;
  if (window.TOPBAR) {
    isLogged = window.TOPBAR.isLogined();
    window.TOPBAR.popup('login');
  }
  return isLogged;
};

export const getTimeStringFromSeconds = (seconds, t) => {
  const hours = parseInt(seconds / 3600, 10) % 24;
  const minutes = parseInt(seconds / 60, 10) % 60;
  let time = '';
  if (hours > 0) {
    time = `${hours} ${t('hour')} ${minutes} ${t('min')}`;
  } else {
    time = `${minutes} ${t('min')}`;
  }
  return time;
};

export const getMetersKm = (distance, t, toFix = 1) => {
  let d = distance;
  if (distance >= 1000) {
    d = (distance / 1000).toFixed(toFix);
    return `${d} ${t('km')}`;
  }
  if (distance < 1000) {
    d = distance.toFixed(toFix);
    return `${d} ${t('m')}`;
  }
};

export const hostResolver = (url) => {
  let host = 'https://chronica.md/map_api';
  if (typeof document !== 'undefined' && document.location.origin && document.location.origin.indexOf('0.0.0.0') < 0 && document.location.origin.indexOf('container--') < 0) {
    host = `${document.location.origin}/map_api`;
  }
  if (process && process.env && process.env.PATH && process.env.NODE_ENV !== 'development') {
    host = 'http://map-api.service.simpals:18211';
  }
  return `${host}${url}`;
};

/* ===================URL Replace/Push Helpers=================== */

export const queryStringToUrl = (object, clear) => {
  const current = document.location.search.substring(1, 1000);
  const parsed = qs.parse(current);
  let newQS = `${qs.stringify({ ...parsed, ...object }, { skipNulls: true })}`;
  if (clear) {
    newQS = `${qs.stringify(parsed, { skipNulls: true })}`;
  }
  return `?${newQS}`;
};

export const addQueryStringToUrl = (object, routerReplace, routerPush) => {
  const currentUrl = document.location.pathname;
  const current = document.location.search.substring(1, 1000);
  const parsed = qs.parse(current);
  const newQS = `${qs.stringify({ ...parsed, ...object }, { skipNulls: true })}`;
  if (current === newQS) return;
  if (routerPush) {
    Router.pushRoute(`${currentUrl}${newQS && newQS.length ? `?${newQS}` : ''}${document.location.hash}`, {});
    return;
  }
  if (routerReplace) {
    Router.replaceRoute(`${currentUrl}${newQS && newQS.length ? `?${newQS}` : ''}${document.location.hash}`, {});
    return;
  }
  window.history.replaceState(
    {},
    '',
    `${document.location.pathname}${newQS && newQS.length ? `?${newQS}` : ''}${document.location.hash}`
  );
};

export const setWaypointsToURL = (waypoints) => {
  const newParams = qs.stringify(waypoints);
  const currentUrl = document.location.pathname;
  Router.replaceRoute(`${currentUrl}?${newParams}${document.location.hash}`, {});
};

export const historyURLReplace = (page) => {
  const currentUrl = document.location.pathname.split('/');
  let lang = DEFAULT_LANG;
  if (isLang(currentUrl[1])) {
    lang = currentUrl[1];
  }
  window.history.replaceState(
    {},
    '',
    `/${lang}/${page && page.length ? `/${page}` : ''}${document.location.search}${document.location.hash}`
  );
};

export const imageUpload = (image) => {
  const formData = new FormData();
  formData.append('file', image);
  return fetch(`${IMAGE_UPLOAD_URL}?template=${encodeURIComponent(IMAGE_UPLOAD_TEMPLATE)}`, {
    method: 'post',
    processData: false,
    contentType: false,
    body: formData
  })
    .then(response => response.json())
    .then((json) => {
      if (json.success) {
        return json;
      }
      console.error('some err', json);
    })
    .catch((error) => {
      console.error(error);
    });
};

export const changeURLPage = (page, clearSearch) => {
  const currentUrl = document.location.pathname.split('/');
  let lang = DEFAULT_LANG;
  if (isLang(currentUrl[1])) {
    lang = currentUrl[1];
  }
  return new Promise((resolve) => {
    Router.pushRoute(
      `/${lang}${page && page.length ? `/${page}` : ''}${
        !clearSearch ? document.location.search : ''
      }${document.location.hash}`,
      {}
    );
    Router.events.on('routeChangeComplete', () => {
      resolve();
    });
  });
};

export const replaceURLPage = ({ page, clearSearch }, onlyHistory) => {
  const currentUrl = document.location.pathname.split('/');
  let lang = DEFAULT_LANG;
  if (isLang(currentUrl[1])) {
    lang = currentUrl[1];
  }
  if (onlyHistory) {
    window.history.replaceState(
      {},
      '',
      `/${lang}/${page && page.length ? `${page}` : ''}${
        !clearSearch ? document.location.search : ''
      }${document.location.hash}`
    );
    return;
  }
  Router.replaceRoute(
    `/${lang}/${page && page.length ? `${page}` : ''}${
      !clearSearch ? document.location.search : ''
    }${document.location.hash}`
  );
};

export const changeRoutesURL = ({ cityId, routeType, routeNumber }) => {
  const currentUrl = document.location.pathname.split('/');
  const spliceFrom = 0;
  const current = document.location.search.substring(1, 1000);
  const parsed = qs.parse(current);
  const newQS = `${qs.stringify(parsed, { skipNulls: true })}`;
  let spliceTo = VIEWPORT_ROUTE_PARAMS;
  if (isLang(currentUrl[1])) {
    spliceTo += 1;
  }
  const prevUrl = currentUrl.splice(spliceFrom, spliceTo);
  Router.pushRoute(
    `${prevUrl.join('/')}/routes/${cityId}${routeType && routeNumber ? (`/${routeType}/${routeNumber}`) : ''}?${newQS}${document.location.hash}`,
    {}
  );
};

/* /===================URL Replace/Push Helpers===================/ */

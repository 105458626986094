const SEARCH_URL = '/companies/webmap/map_company_search';
const GET_STREET_URL = '/companies/webmap/get_street';
const USER_MARKERS_URL = '/companies/webmap/point';
const GET_CATEGORY_URL = '/companies/webmap/get_category';
const GET_CATEGORIES_URL = '/companies/webmap/categories';
const GET_NEAR_URL = '/companies/webmap/near_address';
const GET_ROUTES_URL = '/companies/webmap/routes';
const GET_ROUTE_URL = '/companies/webmap/route';
const GET_OBJECT_URL = '/companies/webmap/get_object';
const GET_BRANCH_URL = '/companies/webmap/get_branch';
const GET_COMPANY_BRANCHES_URL = '/companies/webmap/get_company_branches';
const GET_ALL_CATEGORY_BRANCHES_URL = '/companies/webmap/get_branches_full_list';
const GET_CATEGORY_ITEMS_URL = '/companies/webmap/get_category_items';
const GET_STYLES_URL_MAP = '/tiles/styles/map/style.json';
const GET_STYLES_URL_SATELITE = '/tiles/styles/satelite/style.json';
const GET_3D_OBJECTS = '/api/object/list';
const GET_3D_OBJECT_FILE = '/images/map/objects/';
const GET_STREET_FROM_OSM_URL = '/companies/webmap/get_street_by_osmid';
const GET_BUILDING_FROM_OSM_URL = '/companies/webmap/get_building_by_osmid';
const GET_BRANCH_FROM_OSM_URL = '/companies/webmap/get_branch';
const ADD_PLACE_ERROR_URL = '/companies/webmap/add_error';
const GET_CITY_BY_ID = '/companies/webmap/city';
const OSRM_URL = '/routing/route/v1/';
const PUBLIC_ROUTES_URL = '/routing/route/v1/public-transport/';
const IMAGE_UPLOAD_TEMPLATE = '573a0f8ad38ec81cee50fe1bb573dc601bfc62aacef0c1c59e6be50c8844a230Wre3gsTKyl7E5Juwu3oJsBVSwzNyOroRylU85jD5fTcyWMJdZncxOYGtDR781eMlhzRANDDaEd29/gVs5UIzlvYcp05z2voJCsdjC6xFZq4WhdX2Od3+E47eYonaG8ZckL0N0S1ftSw0V2LkjVECJK10A5CmfHjWyVSWbZRfkoo=CsU3Nd1u9pDsi3iq9G4U5jCJoOdYohZhC/URufdHPPdMOEe5dBDcapmcCtwz+/yjLEU6Cvrsxosd1OSBSbk4SFYcAk18gjVajPg3JGkw7Lm0/TJuKHK1vK9Vo7kbMYIuM4biGxvwf97Qfs9ZYkCF8TcemD/kcg2PVR3eX2aHb/Q=Inqc3YO33GrfXdGE05j2qz9l0YsITtwREZqPfO5x1FUXoMKYS5uyWYlXI+D4s1eCxrHipeBExbWOQOnhSP9KGWy/V8HpRMSwEnDbT1UhVCf4WeH55aIYCWEkEYinlR9Snfc7tZe0IxDphlmPqneftiwOSeCT4EZW2Wq7v5bcDw4=';
const IMAGE_UPLOAD_URL = 'https://i.simpalsmedia.com/upload/';
const IMAGE_URL = 'https://i.simpalsmedia.com/joblist.md/original/';

const INITIAL_LEVEL_ON_LEVELED_BUILDING = 'P';

const NEAMPORNIT_CAT_ID = '443';

const GTM_ID = 'GTM-5943DWZ';

const API_KEY = '8c0e693c-555b-4dbf-8e10-ac8648a8c854';
const ENCODE = Buffer.from(`${API_KEY}:pashalka`).toString('base64');
const AUTHORIZATION = `Basic ${ENCODE}`;

const CHISINAU_ID = 129991407;

const ROUTE_TYPE_TO_ICON = {
  1: 'directions_bus',
  2: 'directions_marshrutka',
  3: 'directions_trolleybus',
};

const MAP_DEFAULTS = {
  lang: 'ru',
  x: 47.023006,
  y: 28.833677,
  z: 14.00,
  p: '0.00',
  b: '0.00'
};

module.exports = {
  SEARCH_URL,
  GET_STYLES_URL_MAP,
  GET_STYLES_URL_SATELITE,
  GET_3D_OBJECTS,
  GET_3D_OBJECT_FILE,
  ROUTE_TYPE_TO_ICON,
  USER_MARKERS_URL,
  NEAMPORNIT_CAT_ID,
  INITIAL_LEVEL_ON_LEVELED_BUILDING,
  AUTHORIZATION,
  GET_STREET_URL,
  GET_CATEGORY_URL,
  IMAGE_UPLOAD_TEMPLATE,
  IMAGE_UPLOAD_URL,
  IMAGE_URL,
  GET_CATEGORIES_URL,
  GET_NEAR_URL,
  GET_ROUTES_URL,
  GET_ROUTE_URL,
  GET_OBJECT_URL,
  GET_BRANCH_URL,
  GET_CATEGORY_ITEMS_URL,
  GET_COMPANY_BRANCHES_URL,
  GET_ALL_CATEGORY_BRANCHES_URL,
  GET_STREET_FROM_OSM_URL,
  GET_BUILDING_FROM_OSM_URL,
  GET_BRANCH_FROM_OSM_URL,
  MAP_DEFAULTS,
  CHISINAU_ID,
  ADD_PLACE_ERROR_URL,
  GET_CITY_BY_ID,
  OSRM_URL,
  PUBLIC_ROUTES_URL,
  GTM_ID
};
